@import 'styles/variables.scss';

.custom-button {
  &--primary {
    &-1 {
      min-width: 160px;
      margin-right: 30px;
      background-color: $color-dark-1;
      border: 2px solid $color-dark-1;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 44px;
      font-weight: 500;
      font-size: 16px;
      border-radius: 27px;
      line-height: 1.5715;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:focus {
        background-color: inherit;
        border-color: inherit;
      }

      &:hover {
        background: $color-primary-4;
        border: 2px solid $color-primary-4;
        color: $color-dark-2;
      }
    }
    &-2 {
      color: $color-dark-1;
      min-width: 160px;
      margin-right: 30px;
      height: 50px;
      border-radius: 100px;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $color-primary;
      height: 44px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-weight: 500;
      font-size: 16px;

      &:focus {
        color: $color-dark-1;
        background-color: inherit;
        border-color: $color-primary-4;
      }

      &:hover {
        background: $color-primary-4;
        border: 2px solid $color-primary-4;
        color: $color-white !important;
      }
    }
  }

  &--label {
    &-selected {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: 4px solid $color-primary;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      margin-left: 5px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:active {
        background-color: inherit;
        border-color: inherit;
      }

      &:focus {
        background-color: inherit;
        border-color: inherit;
      }

      &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;

        & * {
          fill: $color-white !important;
        }
      }
    }
    &-default {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: 4px solid $color-gray-9;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      margin-left: 5px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:active {
        background-color: inherit;
        border-color: $color-gray-9;
      }

      &:focus {
        background-color: inherit;
        border-color: $color-gray-9;
      }

      & * {
        fill: $color-gray-9 !important;
      }

      &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-gray-9;

        & * {
          fill: $color-white !important;
        }
      }
    }
  }
}
