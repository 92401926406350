@import 'styles/variables.scss';

.c-navigation-settings {
  .ant-menu {
    background-color: transparent;
  }

  .ant-menu-item a::before {
    display: none;
  }

  .ant-menu-item a {
    color: #777;
    font-size: 18px;
    position: relative;
    padding-left: 30px;

    svg {
      position: absolute;
      top: 0;
      right: auto;
      bottom: 0;
      left: -10px;
    }
  }

  .ant-menu-item a:hover {
    text-decoration: underline;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;

    a {
      color: $color-dark-1;
    }
  }
}
