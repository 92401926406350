.c-follow-button {
  font-weight: 700;
  font-size: 14px;
  color: #4595ff;
  padding: 7px !important;
  display: grid;
  justify-content: center;

  &:hover {
    background: #daeaff;
    border-radius: 100px;
  }

  &__text {
    margin: auto;
    margin: 0;
  }
}
