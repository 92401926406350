body {
  background-color: #f5f5f5;
  overflow-y: scroll;
}

@font-face {
  font-family: 'Hknova';
  src: url('./fonts/HKNova-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Hknova';
  src: url('./fonts/HKNova-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Hknova';
  src: url('./fonts/HKNova-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Hknova';
  src: url('./fonts/HKNova-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

body {
  font-family: 'Hknova', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
}
