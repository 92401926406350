@import 'styles/variables.scss';

.c-video-thumbnail {
  display: flex;

  &__left {
    margin-right: 16px;
  }

  &__iframe {
    width: 200px;
    height: 110px;
    background-color: #ddd;
  }

  &__video {
    display: flex;
    position: relative;
    width: 200px;
    height: 110px;
    background-color: #ddd;
    cursor: pointer;

    &-img {
      display: block;
      max-width: 200px;
      max-height: 110px;
      width: auto;
      height: auto;
      margin: auto;

      &:hover {
        cursor: pointer;
      }
    }

    &-play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 33px;
      height: 33px;
      border-radius: 50%;
      opacity: 0.9;
      cursor: pointer;
    }
  }

  &__video:hover &__video-play-button {
    opacity: 1;
  }

  &__labels {
    width: 200px;
    display: flex;
    flex-direction: row;

    & > *:first-child {
      margin: 15px 5px auto auto;
    }

    & > *:last-child {
      margin: 15px auto auto 5px;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 12px;
    color: $color-dark-1;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 1.5;
    color: $color-gray-6;
  }

  &__description {
    font-size: 14px;
    line-height: 1.6;
    color: $color-gray-6;
    word-break: break-all;
  }

  &__time {
    color: $color-blue-1;
  }

  &__description-html {
    display: inline;
  }

  &__show-more {
    color: $color-blue-1;
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__modal {
    .ant-modal-footer {
      display: none;
    }

    .ant-modal-body {
      font-size: 16px;
      line-height: 1.6;
    }
  }

  .ant-btn {
    width: 29px;
    height: 29px;
    border-width: 2px;

    svg {
      width: 15px;
    }
  }
}
