@import 'styles/variables.scss';

// TODO: use the same css for sign in and css reset
// use a single scss file
.c-sign-in {
  height: 100vh;
  display: flex;
  align-items: center;

  &__logo {
    position: absolute;
    top: -100px;
    right: 0;
    bottom: auto;
    left: 0;
    display: block;
    margin: auto;
    width: 185px;
    height: 32px;
  }

  .c-heading {
    text-align: center;
    margin-bottom: 24px;
  }

  &__reset {
    text-align: center;
    color: $color-gray-3;
    font-size: 16px;
    font-weight: 300;
    margin-top: 24px;
    margin-bottom: 0;
  }

  .c-card {
    max-width: 550px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .ant-input-password-icon svg {
    width: 23px;
    height: 23px;
  }

  .ant-btn {
    width: 150px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
