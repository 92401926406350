@import 'styles/variables.scss';

.c-table {
  position: relative;
  top: -60px;

  .ant-table-thead > tr > th {
    background-color: transparent;
    text-transform: uppercase;
    font-size: 14px;
    height: 55px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    text-align: right;
  }

  .ant-table-column-title {
    padding-right: 16px;
  }

  td.ant-table-cell:nth-child(1) {
    text-align: left;
  }

  th.ant-table-cell,
  td.ant-table-cell {
    text-align: right;
    padding-right: 0;
    padding-left: 0;
    vertical-align: top;
  }

  .ant-table-column-has-sorters {
    z-index: 9;
    position: relative;
  }

  th.ant-table-cell:nth-child(2) {
    background-color: transparent;
  }

  &__col {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 16px;
    vertical-align: middle;

    a {
      color: $color-dark-1;
    }
  }

  &__danger {
    width: 25px;
    height: 25px;
    display: inline-block;
    background-size: contain;
    position: relative;
    top: 8px;
    background-image: url('../../../assets/images/danger.png');
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 300;
    color: $color-gray-4;
    margin: 0;
  }

  .ant-pagination {
    justify-content: center;
  }
}
