.c-montitor-list-item {
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #3b3934;

  &__info {
    display: flex;
    padding-bottom: 24px;
  }

  &__divider {
    margin-top: 0;
  }

  &__text {
    margin: auto auto auto 0;
  }

  &__impresions {
    margin: auto 20px auto auto;
    display: flex;
  }

  &__impresions-text {
    margin: auto 4px auto auto;
  }
  &__impresions-icons {
    margin: auto auto auto 4px;
  }

  &__click-area {
    height: 100%;
  }

  &__click-area:hover {
    background-color: rgba(29, 28, 26, 0.04);
    transition-duration: 0.3s;
  }
}
