.c-snippet {
  border: 1px solid #e4e3e1;
  border-radius: 5px;
  margin: 10px auto 10px auto;

  &__text {
    margin: 16px;
    font-weight: 500;
    font-size: 16px;
    color: #1d1c1a;
  }

  &__button {
    font-weight: 600;
    font-size: 16px;
    color: #4595ff;
    cursor: pointer;
  }

  &__details {
    background-color: #fafafa;
    width: 100%;
    margin-top: 16px;
    padding: 16px;
    display: flex;
  }

  &__details-left {
    margin: auto auto auto 0;
    font-weight: 600;
    font-size: 14px;
    color: #59564f;
    display: flex;
  }

  &__details-right {
    margin: auto 0 auto auto;
  }

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: #c9c7c3;
    margin: auto 4px auto 4px;
  }

  &__author-img {
    border-radius: 24px;
    width: 24px;
    height: 24px;
    margin: auto 4px auto 4px;
  }

  &__details__dropdown {
    cursor: pointer;
  }

  &__dropdown-item {
    font-weight: 600;
    font-size: 16px;
    color: #3b3934;
    margin: auto 0 auto auto;
    display: flex;
  }

  &__dropdown-text {
    margin: auto auto 10px 8px;
  }

  &__creator-dropdown {
    background-color: white;
    padding: 18px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    &__header {
      display: flex;
    }

    &__header-img {
      margin: 4px;
      width: 32px;
      height: 32px;
      border-radius: 32px;
    }

    &__header-info--name {
      font-weight: 600;
      font-size: 16px;
      color: #1d1c1a;
    }

    &__header-info--data {
      font-weight: 400;
      font-size: 14px;
      color: #77736a;
    }

    &__communities {
      margin-top: 24px;
    }

    &__communities-title {
      font-weight: 700;
      font-size: 12px;
      color: #aeaba5;
    }

    &__communities-list {
      font-weight: 600;
      font-size: 16px;
      color: #4595ff;
    }
  }
}
