.c-action-modal {
  .ant-btn:first-child {
    display: none;
  }
  .ant-btn {
    width: 180px;
  }
  .ant-form-item-control-input-content {
    display: flex !important;
    flex-direction: row;
    & > * {
      margin: auto;
    }
    &.ant-col .ant-col-6 {
      display: flex;
    }
  }
  .ant-row .ant-form-item {
    width: 100%;
  }
  .ant-modal-content {
    padding: 20px 30px 30px 30px;
  }
  .ant-modal-header {
    padding: 0 !important;
    .ant-modal-title {
      font-weight: 600;
      font-size: 20px;
      color: #232222;
    }
  }
  .ant-modal-close-x {
    padding-right: 30px;
  }

  .anticon-close-circle > svg {
    margin-top: 18px;
    width: 25px;
    height: 25px;
  }
  .anticon-close-circle > svg > path:first-child {
    color: #8e8e8e;
  }
  .anticon-close-circle > svg > path:last-child {
    color: #dddddd;
  }
  .ant-select-selector {
    height: 40px !important;
    border: 1px solid #a8a8a8 !important;
    border-radius: 4px !important;
  }
  .ant-btn-primary > span {
    font-weight: bold;
    font-size: 16px;
    color: #232222;
  }
  .ant-form-item {
    font-size: 16px;
  }
}
