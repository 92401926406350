@import 'styles/variables.scss';

.c-secondary-navigation {
  height: 40px;
  background-color: $color-secondary;

  &__container {
    max-width: 1550px;
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .ant-menu {
    height: 100%;
  }

  .ant-menu-title-content a,
  .ant-menu-title-content {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .ant-menu-title-content a {
    padding-right: 25px;
    padding-left: 25px;
  }

  .ant-menu-horizontal > .ant-menu-item a::before {
    display: none;
  }

  .ant-menu {
    width: 100%;
    position: relative;
  }

  .ant-menu-horizontal {
    background-color: $color-secondary;
    border: 0;
  }

  .ant-menu-horizontal > .ant-menu-item a {
    color: $color-white;
  }

  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    background-color: $color-secondary-1;
    height: 40px !important;
  }

  .ant-menu-title-content {
    color: white !important;
    font-weight: 700;
    font-size: 15px;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    display: none;
  }
}
