.c-add-tag {
  display: flex;

  &__dropdown {
    height: 32px;
    width: 100px;
    margin: 8px 13px 0 0;
  }

  &__text {
    margin: auto;
    font-weight: 700;
    font-size: 14px;
    color: #77736a;
  }

  &:hover {
    background-color: #e4e3e1;
    border-radius: 50px;
  }

  &__button {
    display: flex;
    border-radius: 7px;
    height: 28px;
    margin-top: 10px;
    font-weight: 700;
    font-size: 14px;
    color: #77736a;
  }
  &__button-text {
    margin: auto auto auto 10px;
  }

  &__button:hover {
    background-color: #e4e3e1;
  }

  &__dropdown__overlay {
    .ant-input {
      min-height: 44px;
    }
    .ant-input:focus,
    .ant-input:hover {
      box-shadow: 0px 0px 0px 3px rgba(204, 165, 90, 0.2) !important;
      border: 1px solid #cca55a !important;
    }
  }

  &__tag {
    color: #948f87;
    margin: 6px 0;
    font-weight: 600;
  }
  &__tag--used {
    float: right;
    font-size: 13px;
  }
  &__tag--bold {
    color: #3b3934;
  }
}
