$main-color: red;
$secondary-color: green;

$color-primary: #cba459;
$color-primary-2: #cca553;
$color-primary-3: #dec591;
$color-primary-4: #cca55a;

$color-dark-1: #232222;
$color-dark-2: #111;
$color-dark-4: #ec5b56;
$color-gray-1: #dbdad8;
$color-gray-2: #bbbbbb;
$color-gray-3: #aaaaaa;
$color-gray-4: #777777;
$color-gray-5: #d8d8d8;
$color-gray-6: #5c5c5c;
$color-gray-7: #c4c4c4;
$color-gray-8: #b3bac7;
$color-gray-9: #c8c8c8;

$color-mud-10:#F5EDDE;

$color-blue-1: #4595ff;
$color-blue-2:  #DAEAFF;

$color-white: #fff;

$color-secondary: #59564f;
$color-secondary-1: #77736a;

$color-black: #1D1C1A;