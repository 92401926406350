.c-monitor-surprise-modal {
  &__iframe {
    width: 720px;
    aspect-ratio: 16/9;
  }

  .ant-modal-title {
    line-height: 30px;
  }

  .ant-modal-footer {
    display: none;
  }
}
