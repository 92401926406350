.c-snippets-list {
  max-height: 1000px;

  &-head {
    display: flex;
  }

  &__snippets {
    max-height: 875px;
    overflow-y: scroll;
  }

  &__text {
    font-weight: 700;
    font-size: 16px;
    color: #1d1c1a;
  }

  &__surprise {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__results {
    font-weight: 500;
    font-size: 14px;
    color: #948f87;
  }
}
