.c-manage-info {
  &__title {
    font-weight: 500;
    font-size: 24px;
    color: #232222;
    display: flex;

    .no-display {
      display: none;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    color: #777777;
    margin-bottom: 0;
  }

  &__draft__box {
    display: flex;
    margin-left: 8px;
    margin-top: 8px;
    width: 61px;
    height: 24px;
    background: #ffffff;
    border: 1px solid #f5edde;
    box-sizing: border-box;
    border-radius: 12px;
  }

  &__draft__circle {
    width: 7px;
    height: 7px;
    background: #e8ac38;
    border-radius: 7px;
    margin: auto;
  }

  &__draft__text {
    font-weight: 400;
    font-size: 14px;
    color: #3b3934;
    margin: auto;
  }

  &__hover {
    color: #c9c7c3;
    display: flex;
  }

  &__hover-buttons {
    margin: auto 10px;
  }
}
