@import 'styles/variables.scss';

.c-navigation {
  height: 80px;
  background-color: $color-dark-1;

  &__container {
    max-width: 1550px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 100%;
  }

  &__logo {
    height: 28px;
    margin-right: 180px;
    margin-left: 16px;
  }

  .ant-menu {
    height: 100%;
  }

  .ant-menu-title-content a,
  .ant-menu-title-content {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .ant-menu-title-content a {
    padding-right: 25px;
    padding-left: 25px;
  }

  .ant-menu-horizontal > .ant-menu-item a::before {
    display: none;
  }

  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    top: 0 !important;
  }

  &__submenu-popup {
    .ant-menu {
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      border: 0;
    }

    li {
      color: $color-dark-1;
    }

    .ant-menu-item {
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 8px;
      background-color: transparent !important;
    }

    .ant-menu-item:hover {
      color: $color-primary !important;
    }

    .ant-menu-item a:hover {
      color: $color-primary;
    }
  }

  .ant-menu {
    width: 100%;
    position: relative;
  }

  .ant-menu-horizontal {
    background-color: $color-dark-1;
    border: 0;
  }

  .ant-menu-submenu,
  .ant-menu-item {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    padding: 0 !important;
  }

  .ant-menu-horizontal > .ant-menu-item a {
    color: $color-white;
  }

  .ant-menu-submenu {
    color: $color-gray-1;
    background-color: transparent !important;
    position: absolute;
    right: 25px;
    height: 100%;

    .menuitem {
      height: 100%;
      display: flex;
      align-items: center;
    }

    svg {
      position: absolute;
      right: -20px;
      top: 0;
      bottom: 0;
      left: auto;
      margin: auto;
    }
  }

  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: $color-primary;

    svg path {
      fill: $color-primary;
    }
  }

  .ant-menu-horizontal > .ant-menu-submenu:hover a,
  .ant-menu-horizontal > .ant-menu-item:hover a,
  .ant-menu-item-active {
    color: $color-primary !important;
  }

  .ant-menu-horizontal > .ant-menu-item-selected:hover a,
  .ant-menu-horizontal > .ant-menu-submenu-selected:hover a {
    color: $color-white !important;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    display: none;
  }

  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    background-color: $color-primary;
  }

  &__submenu {
    width: 351px !important;
  }

  &__buttons {
    justify-content: center;
    width: 366px !important;
  }
}
