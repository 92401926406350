@import 'styles/variables.scss';

.c-footer {
  border-top: 1px solid $color-gray-1;
  max-width: 1450px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 24px 16px 60px 16px;
  font-weight: 300;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  &__list-item {
    &:after {
      content: '|';
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
    }

    &:last-of-type:after {
      display: none;
    }
  }
}
