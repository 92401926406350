@import 'styles/variables.scss';

.c-define-container {
  margin: 30px auto 0 auto;
  max-width: 1280px;
  width: 100%;
  padding-bottom: 80px;

  .c-card {
    min-height: auto;
  }
}

// Form

.c-define {
  color: $color-dark-1;

  &__title {
    font-size: 28px;
    font-weight: 500;
    max-width: 1000px;
    margin: 30px auto 40px 0;
    position: relative;
  }

  &--1 {
    max-width: 1000px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__item__subtitle {
    font-size: 16px;
    line-height: 1.6;
  }

  &__item .ant-form-item-control {
    margin-bottom: 0px;
  }

  &__item--1 .ant-form-item-control {
    max-width: 730px;
    margin-bottom: 0px;
  }

  &__item--2 .ant-select {
    max-width: 600px;
  }

  &__item--2 {
    margin-bottom: 10px;
  }

  &__item--3 {
    margin-bottom: 36px;
  }

  &__item--4 {
    .ant-input-password,
    .ant-form-item-control-input-content {
      height: auto;
    }

    margin-top: 45px;
  }

  &__item--no-specific-height {
    .ant-input-password,
    .ant-form-item-control-input-content {
      height: auto;
    }
  }

  &__description {
    max-width: 960px;
    margin-bottom: 30px;
    font-size: 16px;
  }

  .ant-input {
    border-color: $color-gray-8;
    padding-left: 13px;
    padding-right: 13px;
  }

  label {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 500;
  }

  &__optional {
    font-weight: 300;
    display: inline-block;
    margin-left: 8px;
  }

  .ant-radio-wrapper span {
    font-weight: 300;
    font-size: 16px;
  }

  .ant-radio-inner::after {
    background-color: $color-primary;
  }

  .ant-radio-wrapper span,
  .ant-radio-checked .ant-radio-inner {
    border-color: $color-dark-1;
  }

  .ant-select-selector {
    min-height: 56px !important;
    padding-left: 16px !important;
  }

  &__dropdown .ant-select-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: $color-dark-1;
    font-size: 16px;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 16px;
  }

  .ant-select-multiple .ant-select-selection-search {
    margin: 0;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
  .ant-select-single.ant-select-open .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
    padding-left: 4px;
  }

  .margin-top {
    margin-top: 0px;
  }

  .extra-tall {
    height: auto !important;
  }

  .ant-form-item-explain.ant-form-item-explain-connected {
    color: #d71e1e;
    font-weight: 700;
    font-size: 14px;
  }

  .c-define-narrative__boolean-search {
    width: 730px;
  }

  .c-define-narrative__boolean-search-form {
    margin-bottom: 100px !important;

    .ant-form-item-explain.ant-form-item-explain-connected {
      position: absolute;
      margin-top: 80px;
    }
  }

  .c-define-narrative__boolean-search__button {
    width: 120px;
    float: left;
  }
}
