@import 'styles/variables.scss';

// form
//---------------------------------------------------------------------------------------
.ant-input {
  border: 1px solid $color-gray-1;
  border-radius: 4px;
}

.ant-form-item-label > label {
  color: $color-dark-1;
}

.ant-form-item-control {
  margin-bottom: 12px;
}

.ant-input-password,
.ant-form-item-control-input-content {
  padding-right: 11px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 56px;
}

.ant-input {
  min-height: 56px;
}

.ant-alert-error {
  margin-bottom: 24px;
}

.ant-form-item-label {
  padding-bottom: 8px !important;
}

.ant-form-item-label > label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

// spin
//---------------------------------------------------------------------------------------
.ant-spin-dot-item {
  background-color: $color-primary;
}

.ant-spin-blur {
  overflow: initial;
}

// divider
//---------------------------------------------------------------------------------------
.ant-divider-horizontal {
  border: 1px solid $color-gray-1;
}

// buttons
//---------------------------------------------------------------------------------------
.c-button--1.ant-btn,
.c-button--2.ant-btn,
.c-button--3.ant-btn {
  background: transparent;
  color: $color-dark-1;
  height: 50px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  display: inline-block;
}

.c-button--1.ant-btn {
  height: 50px;
  border: 2px solid $color-primary;
}

.c-button--2.ant-btn {
  height: 44px;
  border: 2px solid $color-primary;
}

.c-button--3.ant-btn {
  border: 2px solid $color-dark-1;
  background-color: $color-dark-1;
  height: 44px;
  color: $color-white;
}

// alerts
//---------------------------------------------------------------------------------------
.ant-alert {
  margin-bottom: 16px;
}

// selects
//---------------------------------------------------------------------------------------
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $color-blue-1;
  font-weight: 400;
}

// skeleton, used at the moment only for bubble chart
// needs updates if I want to use in multiple places
//---------------------------------------------------------------------------------------

.ant-skeleton-title {
  width: 100% !important;
  display: none;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100% !important;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  height: 230px;
}

.ant-skeleton-paragraph {
  margin: 0 !important;
}

// tooltip
//---------------------------------------------------------------------------------------

.ant-tooltip-inner {
  color: $color-dark-2;
  box-shadow: none;
  padding: 0;
  background: $color-white;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  padding: 16px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
}

.ant-tooltip-arrow {
  display: none;
}

// modal
//---------------------------------------------------------------------------------------

.ant-modal-content {
  border-radius: 8px;
  box-shadow: none;
  padding: 70px 40px 40px 40px;
}

.ant-modal-header {
  border: 0;
  padding-top: 0;
  padding-bottom: 40px;
}

.ant-modal-title {
  font-size: 28px;
  text-align: center;
  font-weight: 300;
}

.ant-modal-body {
  padding: 0;
  font-size: 16px;
}

.ant-modal-footer {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.ant-modal-footer .ant-btn {
  border: 2px solid $color-primary-2;
  border-radius: 28px;
  background-color: transparent;
  color: $color-dark-1;
  font-size: 16px;
  height: 44px;
  padding-right: 25px;
  padding-left: 25px;
}
.ant-modal-footer .ant-btn-primary {
  margin-left: 16px !important;
  background-color: $color-dark-1;
  border: 2px solid $color-dark-1;
  color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 44px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 27px;
  line-height: 1.5715;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    background: $color-primary-4;
    border: 2px solid $color-primary-4;
    color: $color-dark-2;
  }
}
.ant-modal-footer .ant-btn-dangerous {
  margin-left: 16px !important;
  background-color: $color-dark-4;
  border: 2px solid $color-dark-4;
  color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 44px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 27px;
  line-height: 1.5715;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    background: $color-primary-4;
    border: 2px solid $color-primary-4;
    color: $color-dark-2;
  }
}
