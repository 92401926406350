@import 'styles/variables.scss';

// bee chart

.bars rect {
  fill: $color-primary-3 !important;
}

.group {
  display: none;
}

.scroll-container::-webkit-scrollbar-track {
  border: 2px solid $color-primary-3;
  background-color: #f5f5f5;
}

.scroll-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: $color-primary-3;
}
