@import 'styles/variables.scss';

.c-edit-dropdown {
  border-radius: 50%;
  background-color: $color-white;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  padding: 0;

  &__link {
    &--disabled {
      color: #aaa !important;
    }
  }

  &__overlay {
    padding: 0 !important;

    .ant-dropdown-menu {
      padding: 0 !important;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      overflow: hidden;
    }

    .ant-dropdown-menu-item {
      padding: 14px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
.customWidth {
  width: 40%;
}
