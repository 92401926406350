.c-request-modal {
  .ant-btn-primary {
    border: 0;
    background: #1d1c1a !important;
    color: #ffffff !important;

    &:hover {
      background-color: #cca55a !important;
      color: black !important;
    }
  }

  &__centered-text {
    text-align: center;
    font-size: 16px;
    width: 75%;
    margin: auto;
  }

  &__centered-text-wraper {
    display: flex;
    margin-bottom: 32px;
  }

  &__input-text {
    font-weight: 500;
    color: #3b3934;
  }
}

.c-post-request-modal {
  .ant-btn-primary {
    display: none;
  }

  .ant-btn {
    border: 0;
    background: #1d1c1a !important;
    color: #ffffff !important;

    &:hover {
      background-color: #cca55a !important;
      color: black !important;
    }
  }
}
