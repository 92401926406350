.c-define-narrative-matching-results {
  padding: 32px;
  width: 730px;
  margin-top: 32px !important;
  margin-bottom: 5px;
  background-color: #f9f6ef;
  font-size: 20px;
  color: #232222;

  &__title {
    margin: 0 auto;
  }

  &__warning {
    color: #4595ff;
    width: 405px;
    height: 64px;
    display: flex;
  }
  &__warning-icon {
    color: #4595ff;
    margin: 11px 11px auto 11px;
    width: 29px;
    height: 18px;
  }
  &__warning-text {
    margin: 8px 8px 8px auto;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #4595ff;
  }

  &__no-results-back {
    width: 410px;
    margin-top: 16px;
    font-weight: 600;
    font-size: 13px;
    color: #77736a;
    display: flex;
  }
  &__no-results-back__text {
    width: 357px;
  }
  &__no-results-back__icon {
    margin: 0 11px auto 0;
    width: 18px;
    height: 18px;
  }

  &__no-results {
    width: 410px;
    margin-top: 16px;
    font-weight: 600;
    font-size: 13px;
    color: #77736a;
    display: flex;
  }
  &__no-results__text {
    width: 357px;
  }
  &__no-results__icon {
    margin: 0 11px auto 0;
    width: 18px;
    height: 18px;
  }
}
