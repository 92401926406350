.c-manage__modal {
  .ant-modal-footer {
    display: none;
  }
  &__generic-info {
    margin-top: 19px;
    display: flex;
  }

  &__generic-info__box:first-of-type {
    margin-right: 20px !important;
  }

  &__subtitle {
    color: #948f87;
  }

  &__subtitle__links {
    display: flex;
    flex-direction: row !important;
    margin-bottom: 8px;
    height: auto;

    .Image {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 7px;

      width: 130px;
      height: auto;
    }

    .LowerContainer {
      width: 500px;
    }
  }

  &__subtitle__search {
    width: 100%;
  }

  &__category {
    margin-bottom: 8px;
  }

  &__is-MLG {
    color: #bf20cd;
    background: #fde4ff;
    border: 1px solid #bf20cd;
    border-radius: 4px;
    font-weight: 800;
    font-size: 12px;
    margin-left: 0 !important;
    height: 26px;
    padding: 2px 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 4px !important;
  }

  &__title {
    display: flex;
    margin: auto;
  }

  &__title__wraper {
    display: flex;
  }

  &__header-buttons {
    transform: translateX(-80px);
    display: flex;
    width: 140px !important;
    height: 50px;
  }

  &__header-buttons-item {
    margin: auto 12px;
  }
}
