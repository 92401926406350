@import 'styles/variables.scss';

.c-users {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // TODO: Create a generic Button component with all the stylings
  &__add {
    border: 2px solid $color-primary-4;
    box-sizing: border-box;
    width: 200px;
    height: 48px;
    border-radius: 200px;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      color: $color-white;
      background: $color-primary-4;
      border-color: $color-primary-4;
    }
    &__modal > .ant-modal > .ant-modal-content > .ant-modal-body {
      display: flex;
      flex-direction: column;
    }
    &__modal {
      .ant-btn:first-child {
        display: none;
      }
      .ant-btn {
        width: 180px;
      }
      .ant-form-item-control-input-content {
        display: flex !important;
        flex-direction: row;
        & > * {
          margin: auto;
        }
        &.ant-col .ant-col-6 {
          display: flex;
        }
      }
      .ant-row .ant-form-item {
        width: 100%;
      }
    }
  }

  .ant-table {
    overflow: auto;
  }

  .ant-table-thead > tr > th {
    background-color: $color-white;
    border-bottom: 2px solid $color-gray-1;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 20px 16px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #dddddd;
  }
  &__remove-modal {
    .ant-btn:first-child {
      display: none;
    }
    &-div {
      display: flex;
      flex-direction: column;
      & > * {
        margin: 10px auto;
      }
    }
  }
}
