.black-notifiaction {
  .ant-notification-notice-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    color: white;
    position: relative;
    width: 383px;
    height: 40px;
    background: #1d1c1a;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
  }

  .ant-notification-notice {
    padding: 0px !important;
  }

  .anticon {
    display: none;
  }
}
