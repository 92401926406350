.c-define-narrative-modals {
  .ant-modal-body {
    margin-bottom: 32px;
    text-align: center;
  }

  &--one-button {
    .ant-btn:first-of-type {
      display: none;
    }
  }
}
