.c-monitor-tag-list {
  &__header {
    display: flex;
    margin-bottom: 24px;
  }

  &__header-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #1d1c1a;
    margin: auto auto auto 0;
  }

  &__items {
    overflow-y: scroll;
  }
}
