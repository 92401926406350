.c-creator {
  &__image {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    margin-right: 10px;
  }
  &__text {
    font-weight: 400;
    font-size: 16px;
  }
}
