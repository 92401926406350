@import 'styles/variables.scss';

.c-reset-pass {
  height: 100vh;
  display: flex;
  align-items: center;

  &__logo {
    position: absolute;
    top: -100px;
    right: 0;
    bottom: auto;
    left: 0;
    display: block;
    margin: auto;
    width: 185px;
    height: 32px;
  }

  .c-card {
    max-width: 552px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .ant-btn {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .custom-button--primary-2 {
    max-width: 160px;
    min-width: 160px;
  }

  .ant-form-item-required {
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
    font-size: 16px;
    color: #232222;
  }

  .ant-input-password-icon svg {
    width: 23px;
    height: 23px;
  }

  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: #fff1f1;
  }
  .ant-form-item-explain-error {
    font-weight: 700;
    font-size: 12px;
    margin-top: 5px;
    color: #d71e1e;
  }
}
