@import 'styles/variables.scss';

.c-tags-list {
  &__item {
    height: 32px;
    border: 0;
    background: rgba(239, 227, 203, 0.4);
    font-size: 16px;
    color: $color-dark-1;
    padding: 0 9px;
    display: flex;
    align-items: center;
    font-weight: 300;

    z-index: 10;
    position: relative;

    .ant-tag-close-icon {
      height: 17px;
      margin-left: 12px;
    }
  }

  &__menu-item {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item--parent {
    margin-top: 8px !important;
  }
}
