.c-share-modal {
  &__subtitle {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3b3934;
  }

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3b3934;
    text-transform: capitalize;
  }

  &__user-search {
    margin-bottom: 8px;
  }

  &__user {
    display: flex;
    margin: 8px auto;
  }
  &__user-details--you-tag {
    color: #948f87;
  }

  &__user-details--name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3b3934;
  }

  &__user-details--email {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #948f87;
  }

  &__user-role {
    display: flex;
    margin-left: auto;
    margin-right: 0;
  }

  &__user-role-click {
    margin: auto;
  }

  &__user-role-click-text {
    margin: auto;
  }

  &__separator {
    border: 1px solid #e4e3e1;
  }

  &__user-role-arrow {
    margin-left: 9px;
  }

  .ant-modal-footer {
    margin-bottom: 14px;
  }

  .ant-btn-primary {
    border: 0;
    background: #1d1c1a !important;
    color: #ffffff !important;

    &:hover {
      background-color: #cca55a !important;
      color: black !important;
    }
  }
}
