.c-monitor-heat-map {
  margin-top: 20px;
  &__row {
    display: flex;
  }

  &__item {
    height: 100px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin: 4px;
    overflow: hidden;
    word-wrap: break-word;
  }

  &__item--name {
    margin: 0 auto auto 0;
  }

  &__item--impresions {
    margin: auto auto 0 0;
  }
}
