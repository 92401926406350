@import 'styles/variables.scss';

.c-table-tags {
  &__row {
    display: flex;
    align-items: center;
  }

  &__container {
    display: flex;
    align-items: center;
    border: 1px solid $color-gray-8;
    border-radius: 4px;
    height: 56px;
    width: 100%;
    overflow: auto;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__list-item {
    height: 32px;
    border: 0;
    background: rgba(239, 227, 203, 0.4);
    font-size: 16px;
    color: $color-dark-1;
    padding: 0 9px;
    display: flex;
    align-items: center;
    font-weight: 300;
    margin-top: 8px;
    margin-right: 13px;
    z-index: 10;
    position: relative;

    .ant-tag-close-icon {
      height: 17px;
      margin-left: 12px;
    }
  }
}
