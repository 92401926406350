@import 'styles/variables.scss';

.c-list-page-heading {
  font-weight: 500;
  font-size: 30px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 44px;

  &__star {
    margin-right: 9px;
  }

  &__title {
    font-size: 30px;
    margin: 0 16px 0 0;
  }

  &__link {
    margin-left: 24px;
    border: 2px solid $color-primary-2;
    font-size: 16px;
    color: $color-dark-1;
    border-radius: 28px;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-weight: 400;
    min-width: 160px;

    &:hover {
      background-color: $color-primary-4;
      color: $color-white;
    }
  }

  &__child {
    display: flex;
    margin-right: 30px;
    margin-left: 20px;
  }

  &__child-icon {
    margin: auto 11px;
  }
}
