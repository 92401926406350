@import 'styles/variables.scss';

.c-define-narrative-actions {
  background: $color-white;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.c-define-narrative-actions__button--save {
  width: 100px !important;
  min-width: 100px !important;
  color: $color-blue-1;
  background-color: transparent !important;
  border: 0 !important;
  &:hover {
    background-color:$color-blue-2 !important;
    color: $color-blue-1 !important;
  
  }
  
}

.c-define-narrative-actions__button--cancel {
  width: 110px !important;
  min-width: 110px !important;
  background: $color-white;
  border: 2px solid $color-primary-4;
  border-radius: 100px;
  &:hover {
  background: $color-mud-10;
  border: 2px solid $color-primary-4;
  color:#1D1C1A !important;
  }
}

.c-define-narrative-actions__wraper {
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.c-define-narrative-actions__right {
  border: 0px !important;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;

}
