.c-monitor-list-filter {
  font-weight: 700;
  font-size: 12px;
  color: #59564f;
  margin: auto 0 auto auto;
  text-transform: uppercase;

  &--text {
    color: #948f87;
  }

  &__dropdown-item {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3b3934;
    margin: auto 10px;

    .ant-dropdown-menu-title-content {
      display: flex !important;
    }
  }

  &__dropdown-check {
    margin: auto 0 auto auto;
  }

  &__divider {
    margin: 8px 0px;
  }

  &__buttons-holder {
    display: flex;
  }

  &__buttons {
    min-width: 0px;
    min-height: 0px;
    height: auto;

    padding: 3px 7px;

    font-weight: 600;
    font-size: 14px;
    margin: 6px auto;
  }

  &__dropdown {
    width: 276px;
    min-width: 276px !important;
  }
}
