@import 'styles/variables.scss';

.c-video-extra {
  text-align: left;
  position: relative;
  padding-left: 40px;

  &__img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 16px;
    position: absolute;
    top: -5px;
    left: -5px;
    overflow: hidden;
  }

  &__channel {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    color: $color-dark-1;
  }

  &__stats {
    font-size: 14px;
    color: $color-gray-6;
    margin-bottom: 10px;
  }

  .ant-tag {
    font-size: 14px;
    color: $color-primary-2;
    background-color: transparent;
    border: 1px solid #c9c9c9;
    border-radius: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
  }
}
